import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import PlainHeader from "components/ui/base/PlainHeader";
import NumberSteps from "components/ui/base/NumberSteps";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import ReadPost from "components/ui/base/ReadPost";
import FullWidthImage from "components/ui/base/FullWidthImage";
import TestimonialWithImageFrancisco from "components/ui/extended/TestimonialWithImageFrancisco";
import VideoPreview from "components/ui/base/VideoPreview";
import RegisterForEmailMarketing from "components/ui/extended/RegisterForEmailMarketing";
import Customers from "components/ui/extended/Customers";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";

interface EmailMarketingPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: EmailMarketingPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.email-marketing.seo.title")}
        description={t("pages.features.email-marketing.seo.description")}
        image={{
          relativePath: "meta/email-marketing-software.jpg",
          alt: "Help Desk Software with Help Center, Live Chat",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.features.email-marketing.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.features.email-marketing.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.features.email-marketing.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.features.email-marketing.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.features.email-marketing.title")}
        description={t("pages.features.email-marketing.description")}
        image={{
          relativePath:
            "pages/features/email-marketing/email-marketing-software.jpg",
          alt: "Email marketing software",
        }}
        titleMaxWidth={800}
        descriptionMaxWidth={600}
        ctaRegister={{ show: true }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Email Marketing",
            path: "/features/email-marketing/",
          },
        ]}
      />

      <NumberSteps
        title={t("pages.features.email-marketing.steps.title")}
        titleMaxWidth={800}
        elements={Array.from(Array(4).keys()).map((i) => {
          return {
            title: t(`pages.features.email-marketing.steps.${i + 1}.title`),
            description: t(
              `pages.features.email-marketing.steps.${i + 1}.description`
            ),
          };
        })}
      />

      <ImageWithDescription
        image={{
          relativePath:
            "pages/features/email-marketing/email-marketing-software-list-creation.jpg",
          alt: t("pages.features.email-marketing.lists.title"),
          position: "left",
          fullWidth: true,
        }}
        title={t("pages.features.email-marketing.lists.title")}
        description={t("pages.features.email-marketing.lists.description")}
        cta={{
          link: "/features/audience-segmentation/",
          text: t("pages.features.email-marketing.lists.cta"),
        }}
      />

      <ImageWithDescription
        image={{
          relativePath:
            "pages/features/email-marketing/craft-email-templates-with-email-template-builder.jpg",
          alt: t("pages.features.email-marketing.craft-emails.title"),
          position: "right",
          fullWidth: true,
        }}
        title={t("pages.features.email-marketing.craft-emails.title")}
        description={t(
          "pages.features.email-marketing.craft-emails.description"
        )}
        cta={{
          link: "/features/email-template-builder/",
          text: t("pages.features.email-marketing.craft-emails.cta"),
        }}
      />

      <ImageWithDescription
        id={"delivery-rate"}
        title={t("pages.features.email-marketing.deliverability-rate.title")}
        description={t(
          "pages.features.email-marketing.deliverability-rate.description"
        )}
        image={{
          relativePath:
            "pages/features/email-marketing/email-marketing-software-100-deliverability-rate.jpg",
          alt: t("pages.features.email-marketing.deliverability-rate.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ReadPost
        title={"How to improve by 40% your open rate with one trick"}
        path={
          "/blog/marketing/email-marketing/2019/01/15/improve-your-email-open-rate-by-40-with-this-secret/"
        }
      />

      <FullWidthImage
        id={"campaign-insights"}
        title={t("pages.features.email-marketing.reports.title")}
        description={t("pages.features.email-marketing.reports.description")}
        image={{
          relativePath:
            "pages/features/email-marketing/email-marketing-software-campaign-reports.jpg",
          alt: t("pages.features.email-marketing.reports.title"),
        }}
      />

      <FullWidthImage
        id={"user-detail-reporting"}
        title={t("pages.features.email-marketing.customer-reports.title")}
        description={t(
          "pages.features.email-marketing.customer-reports.description"
        )}
        descriptionMaxWidth={650}
        cta={{
          link: "/features/crm/",
          text: t("pages.features.email-marketing.customer-reports.cta"),
        }}
        image={{
          relativePath:
            "pages/features/email-marketing/email-marketing-software-crm-user-details.jpg",
          alt: t("pages.features.email-marketing.customer-reports.title"),
        }}
      />

      <TestimonialWithImageFrancisco />

      <VideoPreview
        title={t("pages.features.email-marketing.automate.title")}
        paragraph={t("pages.features.email-marketing.automate.description")}
        cta={{
          link: "/solutions/marketing-automation/",
          text: t("pages.features.email-marketing.automate.cta"),
        }}
        storiesPosition={"right"}
        stories={[
          {
            relativePath:
              "pages/features/marketing-funnel/marketing-automation-funnel-template-builder.mp4",
          },
        ]}
      />

      <RegisterForEmailMarketing />

      <Customers />
    </GlobalWrapper>
  );
};

export const EmailMarketingQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/email-marketing-software.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
